export const en = {
  details: {
    titles: {
      flight_details: 'Flight Details',
    },
    headings: {
      connecting_leg_cabin_bookings: 'Connecting Booking Details',
    },
    labels: {
      flight_path: 'Flight Path',
      flight_line: 'Flight Line',
    },
    invalid_fields: {
      flight_number_not_in_suggestions: 'Flight number not in suggested flight numbers',
    },
    leg_aircraft: {
      leg: 'Leg',
      departure_date_short: 'dDate',
      departure_date: 'Departure Date',
      departure_time_short: 'dTime',
      departure_time: 'Departure Time',
      arrival_time_short: 'aTime',
      arrival_time: 'Arrival Time',
      status: 'Status',
      equipment_type_short: 'EqpT',
      equipment_type: 'Equipment Type',
      equipment_version_short: 'EqpV',
      equipment_version: 'Equipment Version',
      sales_configuration_short: 'Config',
      sales_configuration: 'Sales Configuration',
      crossing_flights_short: 'X-ing Flts',
      crossing_flights: 'Crossing Flights',
    },
    crossing_flights: {
      journey_details: 'Journey Details',
      table_columns: {
        origin: 'Origin',
        origin_short: 'Org',
        destination: 'Destination',
        destination_short: 'Des',
        flight_line: 'Flight Line',
        flight_path: 'Flight Path',
        stops: 'Stops',
        departure_date_short: 'dDate',
        departure_date: 'Departure Date',
        day_of_week: 'DoW',
        arrival_date_short: 'aDate',
        arrival_date: 'Arrival Date',
        departure_time_short: 'dTime',
        departure_time: 'Departure Time',
        arrival_time_short: 'aTime',
        arrival_time: 'Arrival Time',
        cabin_code: 'Cabin',
        local_bookings: 'Local Bookings',
        local_bookings_short: 'Local Bk',
        connecting_bookings: 'Connecting Bookings',
        connecting_bookings_short: 'Conx Bk',
      },
      add_to_queue: 'Add selected flight to Review Queue | Add {n} selected flights to Review Queue',
      view_flights_in_review: 'View selected flights in Flight Review',
      flights_added_to_queue: 'The selected flight is added to the review queue | {n} Flights are added to the review queue',
      flights_in_queue: 'No Flights in Queue | One Flight in Queue | {n} Flights in Queue',
    },
    ndo_info:
      'Click the arrows to search for a valid departure date. SRM Flex searches consecutive seven-day periods and displays the first valid flight it finds. The search will stop if no flight can be found within the seven-day period.',
    expected_no_shows: 'Expected No Shows',
    expected_no_shows_short: 'Expected NS',
    local_revenue_short: 'Local Rev',
    local_revenue: 'Local Revenue',
    connecting_revenue_short: 'Conx Rev',
    connecting_revenue: 'Through/Connecting Revenue',
    network_revenue_short: 'Ntwrk Rev',
    network_revenue: 'Network Revenue',
    overbooking_autopilot: 'Overbooking Autopilot',
    no_overbooking_risk_values_found: 'No Overbooking Risk Values for this Flight have been found',
    overbooking_autopilot_short: 'OVB Autopilot',
    overbooking_autopilot_tooltip:
      'Turning on OVB Autopilot will apply the recommended overbooking factor to the overbooking factor immediately, and each day',
    overbooking_factor_mode: 'Overbooking Factor Mode',
    overbooking_risk: 'Overbooking Risk',
    overbooking_risk_values: 'Overbooking Risk Values',
    overbooking_risk_mode: 'Risk Mode',
    overbooking_tactic: 'Overbooking Tactic',
    overbooking_tactic_short: 'OVB Tactic',
    recommended_authorized_capacity: 'Recommended Authorized Capacity',
    recommended_overbooking_factor_short: 'rOVB Factor',
    recommended_overbooking_factor: 'Recommended Overbooking Factor',
    unbalanced_adjustment_short: 'Unbal Adj',
    unbalanced_adjustment: 'Unbalanced Adjustment',
    allotment_short: 'Allot',
    allotment: 'Allotment',
    match_unbalanced_adjustment: 'Adjust Unbalanced Adjustment to Match',
    set_recommended_overbooking_and_apply_to_overbooking: 'Set rOVB and apply to OVB Factor',
    set_recommended_overbooking: 'Set rOVB',
    apply_recommended_overbooking: 'Apply rOVB',
    view_overbooking_risk_values: 'View Overbooking Risk Values',
    forecast_bandwidth: 'Forecast Range',
    forecast: 'Forecast',
    error_loading_forecast_data: 'Error loading forecast data',
    seats: 'Seats',
    laf: 'LAF',
    remaining_seats_available: 'Remaining Seats Available',
    bid_price: 'Bid Price',
    bid_price_vector: 'Bid Price Vector',
    no_bid_price_data_available: 'Bid Price information is not available for this flight cabin',
    flight_cabin_inventory_tactics: {
      columns: {
        bid_price: 'Bid Price',
        class_configuration: 'Class Configuration Name',
        class_configuration_short: 'Cls Cfg',
      },
    },
    flight_line_inventory_grid: {
      columns: {
        byos_remaining_demand: 'BYOS Base Remaining Demand',
        byos_remaining_demand_short: 'BYOS Base RD',
        byos_standard_deviation: 'BYOS Base Standard Deviation',
        byos_standard_deviation_short: 'BYOS Base SD',
      },
    },
    booking_details: 'Booking Details',
    has_forecasting_data: 'This flight has Forecasting enabled',
    forecasting_abbr: 'FCST',
  },
};
